import { BACKEND_API_BASE_URL } from "./BackendBaseUrl";

const httpRequest = async (apiURL, data, method, contentType = "application/json") => {
    let config = {
        method,
        headers: {
            
            "Access-Control-Allow-Origin": "*",
            "Authorization": localStorage.getItem("token"),
            "project": window.location.hostname
        },
        mode: 'cors',
    };

    if (method === "POST") {
        if (contentType === "application/json") {
            config.headers["Content-Type"] = "application/json";
            config.body = JSON.stringify(data);
        } else if (contentType === "multipart/form-data") {
            config.headers["mimetype"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const formData = new FormData();
            for (const key in data) {
               // console.log(key, data);
                formData.append(key, data[key]);
            }
            
            config.body = formData;
        }
    } else {
        config.headers["Content-Type"] = "application/json";
    }
    //console.log(config);
    try {
       // window["showloadingmodel"]();
        let res = await fetch(BACKEND_API_BASE_URL + apiURL, config);
       // window["distroyloadingmodel"]();
        return await res.json();
    } catch (err) {
        // console.log(err);
        return { status: false, message: "Server Error" };
    }
};

export default httpRequest;
