import React from 'react';
import CarouselWithAutoRotate from "./CarouselWithAutoRotate";
import MultiStepForm from './MultiStepForm';
import { Link } from 'react-router-dom';

function FormWithBanner() {
    const images = [
        "assets/images/client/new_banner2_a.svg",
        "assets/images/client/new_banner2_b.svg",
        "assets/images/client/new_banner2_c.svg"
      ];

  return (
    <section>
      <div className="container pt-4 pt-md-0">
        <div className="row justify-content-between">
          <div className="col-lg-6 mb-6 mb-lg-0">
            <div className="d-flex flex-column">

              <div className="mt-md-auto">
                <div className='swiper'>
                <CarouselWithAutoRotate images={images} interval={3000} />

                </div>
              </div>
            </div>  
          </div>

          <div className="col-lg-6 col-xl-5">
            <MultiStepForm />
          </div>


        </div>
      </div>
    </section>
  );
}

export default FormWithBanner;
