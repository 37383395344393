import React from 'react'
import Header from './Header'
import FormWithBanner from './FormWithBanner'
import Footer from './Footer'

const Home = () => {
  return (
    <div>
        <Header />
        <FormWithBanner />
        <Footer />
    </div>
  )
}

export default Home