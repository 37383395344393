import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div>
      <Header />
      <section className="pt-7 pt-md-8">
        <div className="container pt-4 pt-md-0">
          <div className="row justify-content-between">
            <div className="col-lg-12 mb-12 mb-lg-0">
              <h1>Effective Date: March 17, 2024</h1>
              <h1>Welcome to Karz Loans</h1>
              <h2>Terms of Service</h2>
              <p>
                These Terms of Service ("Terms") govern your access to and use
                of the Karz.io website (the "Website") and any services offered
                through the Website (the "Services").
              </p>
              <p>By using the Website, you agree to be bound by these Terms.</p>
              <h3>1. Eligibility</h3>
              <p>
                You must be at least 18 years old and have the legal capacity to
                enter into this agreement to use the Website.
              </p>
              <h3>2. Use of the Website</h3>
              <p>
                You agree to use the Website for lawful purposes only and in
                accordance with these Terms. You agree not to use the Website:
              </p>
              <ul>
                <li>
                  In any way that violates any applicable law or regulation.
                </li>
                <li>
                  For any purpose that is prohibited by these Terms, or that
                  infringes on the rights of others.
                </li>
                <li>
                  To harm the Website or interfere with the use of the Website
                  by others.
                </li>
              </ul>
              <h3>3. Content</h3>
              <p>
                The content on the Website, including text, graphics, images,
                and other materials ("Content"), is the property of Karz.io or
                its licensors and is protected by copyright and other
                intellectual property laws. You agree not to copy, modify,
                distribute, transmit, display, perform, sell, or create
                derivative works from the Content without the express written
                permission of Karz.io.
              </p>
              <h3>4. Disclaimer</h3>
              <p>
                The Website and the Services are provided on an "as is" and "as
                available" basis. Karz.io makes no representations or
                warranties of any kind, express or implied, with respect to the
                Website or the Services, including without limitation, the
                accuracy, completeness, reliability, performance, or security of
                the Website or the Services.
              </p>
              <h3>5. Limitation of Liability</h3>
              <p>
                To the maximum extent permitted by applicable law, Karz.io will
                not be liable for any damages arising out of or in connection
                with your use of the Website or the Services, including without
                limitation, direct, indirect, incidental, consequential,
                punitive, or special damages, even if Karz.io has been advised
                of the possibility of such damages.
              </p>
              <h3>6. Indemnification</h3>
              <p>
                You agree to indemnify and hold harmless Karz.io and its
                officers, directors, employees, agents, and licensors from and
                against any and all claims, losses, damages, liabilities, costs,
                and expenses (including attorney's fees) arising out of or in
                connection with your use of the Website or the Services.
              </p>
              <h3>7. Termination</h3>
              <p>
                Karz.io may terminate your access to the Website or the Services
                for any reason, at any time, without notice.
              </p>
              <h3>8. Governing Law</h3>
              <p>
                These terms and conditions are governed by and construed in
                accordance with the laws of India and you irrevocably submit to
                the exclusive jurisdiction of the courts in Delhi.
              </p>
              <h3>9. Entire Agreement</h3>
              <p>
                These Terms constitute the entire agreement between you and
                Karz.io regarding your use of the Website and the Services.
              </p>
              <h3>10. Severability</h3>
              <p>
                If any provision of these Terms is held to be invalid or
                unenforceable, such provision shall be struck and the remaining
                provisions shall remain in full force and effect.
              </p>
              <h3>11. No Waiver</h3>
              <p>
                No waiver of any provision of these Terms by Karz.io shall be
                deemed a further or continuing waiver of such provision or any
                other provision.
              </p>
              <h3>12. Contact Us</h3>
              <p>
                If you have any questions about these Terms, please contact us:
              </p>
              <ul>
                <li>
                  By email:{" "}
                  <Link to={"mailto:contact@karz.io"}>contact@karz.io</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TermsOfService;
