import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <section className="pt-7 pt-md-8">
        <div className="container pt-4 pt-md-0">
          <div className="row justify-content-between">
            <div className="col-lg-12 mb-12 mb-lg-0">
              <h2>1. Introduction:</h2>
              <p>
                We appreciate your trust in Karz.io. This Privacy Policy
                elucidates how we handle, utilize, and share the personal
                information collected through our website, which is aimed at
                connecting users with potential lending partners.
              </p>

              <h2>2. Information Collection:</h2>
              <p>
                a. We collect various user data, such as name, email, mobile
                number, city, loan amount, loan purpose and other loan other
                details.
              </p>
              <p>
                b. The information is obtained through user interactions with
                our website forms and/or Whatsapp chatbots.
              </p>

              <h2>3. Purpose of Information:</h2>
              <p>
                a. Collected data is employed to match users with lending
                partners tailored to their specific needs.
              </p>
              <p>
                b. It facilitates the provision of personalized and pertinent
                loan options.
              </p>

              <h2>4. Information Sharing:</h2>
              <p>
                a. User information is shared with our network of lending
                partners.
              </p>
              <p>
                b. The selection of lending partners is based on the user's
                specified loan requirements.
              </p>

              <h2>5. Security Measures:</h2>
              <p>a. We employ robust security measures to protect user data.</p>
              <p>
                b. Regular assessments and updates are conducted to ensure the
                ongoing integrity and security of the data.
              </p>
              <p>
                c. We employ industry-standard security measures to safeguard
                your data from unauthorized access, disclosure, alteration, and
                destruction. Your privacy and the security of your information
                are our top priorities.
              </p>

              <h2>6. Cookies and Tracking:</h2>
              <p>
                a. Our website may utilize cookies to enhance user experience.
              </p>
              <p>
                b. Cookies may be employed for analytics, and users can opt out
                through browser settings.
              </p>

              <h2>7. Third-Party Links:</h2>
              <p>a. Our website may contain links to third-party websites.</p>
              <p>
                b. We disclaim responsibility for the privacy practices or
                content of external sites.
              </p>

              <h2>8. Data Retention:</h2>
              <p>
                a. User data is retained for the duration necessary to fulfill
                the intended purpose.
              </p>
              <p>
                b. Users have the right to request deletion of their data, which
                can be initiated by contacting us.
              </p>

              <h2>9. Policy Updates:</h2>
              <p>
                a. This policy may be updated to reflect changes in data
                processing practices.
              </p>
              <p>
                b. Users will be duly informed of any substantial policy
                amendments.
              </p>

              <h2>10. User Rights:</h2>
              <p>
                a. Users possess the right to access, correct, or delete their
                personal information.
              </p>
              <p>
                b. Requests related to data actions can be communicated to{" "}
                <Link to={"mailto:contact@karz.io"}>contact@karz.io</Link>.
              </p>

              <h2>11. Legal Compliance:</h2>
              <p>
                a. We adhere to applicable data protection laws and regulations.
              </p>
              <p>
                b. Personal information may be disclosed if required by law.
              </p>

              <h2>12. Contact Information:</h2>
              <p>
                For any privacy-related inquiries, feel free to contact us at{" "}
                <Link to={"mailto:contact@karz.io"}>contact@karz.io</Link>.
              </p>

              <p>
                By utilizing our website, users explicitly acknowledge and agree
                to the terms and practices outlined in this comprehensive
                Privacy Policy.
              </p>

              <p>Karz.io</p>
              <p>Delhi</p>
              <p>
                <Link to={"mailto:contact@karz.io"}>contact@karz.io</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
