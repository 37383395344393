import React, { useState, useEffect } from "react";
import "./Khotline.css";
import { Link } from "react-router-dom";

const Khotline = ({ onClose }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.querySelector('.dialog-box').contains(event.target)) {
        onClose(); // Trigger onClose function when clicking outside of the dialog box
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <>
      <div className="dialog-backdrop">
        <div className="dialog-box">
          <div className="header">
            <center><h5>K-Hotline</h5></center>
          </div>
          <br />
          <div className="content">
            <div className="row row-cols-1 row-cols-md-1 g-4">
              {/* whatsapp info */}
              <div className="col">
                <div className="card card-body bg-default border p-sm-5" style={{"background-color": "white", "box-shadow": "0px 0px 10px rgba(0, 0, 0, 0.5)" }}>
                  {/* Icon */}
                  <div className="mb-4"><i className="bi bi-whatsapp fa-xl text-primary" /></div>
                  {/* Title */}
                  <h6 className="mb-3">WhatsApp us</h6>
                    <p style={{maxWidth: "267px"}}>Just answer 3 quick questions and we will get back with the best offer for you. No spam call, it’s a promise.</p>

                    <Link to={"https://api.whatsapp.com/send?phone=918279752320&text=Hi"} target='_blank' className="btn btn-primary btn-lg col-md-12" style={{textAlign: "justify"}} type="button"><i className='bi bi-whatsapp'></i> WhatsApp us <i class="bi bi-chevron-right" style={{float: "right"}}></i></Link>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Khotline;
