import { useEffect } from "react";
import { BrowserRouter, Route, Routes  } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./landingPage/Home";
import TermsOfService from "./landingPage/TermsOfService";
import PrivacyPolicy from "./landingPage/PrivacyPolicy";



function App() {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    Promise.all([
      // loadScript(`${process.env.PUBLIC_URL}/assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js`),
      loadScript(`${process.env.PUBLIC_URL}/assets/vendor/aos/aos.js`),
      loadScript(
        `${process.env.PUBLIC_URL}/assets/vendor/purecounterjs/dist/purecounter_vanilla.js`
      ),
      loadScript(`${process.env.PUBLIC_URL}/assets/js/functions.js`),
    ])
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });

    // Cleanup: No need to remove the scripts because they are added to the body and will not interfere with subsequent renders
  }, []); // Empty dependency array means this effect runs once after initial render


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route excat path="/" element={<Home />} />
          <Route excat path="/terms-service" element={<TermsOfService />} />
          <Route excat path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route excat path="*" element={<PrivacyPolicy />} />
        </Routes>

        {/* <HomePage />  */}
        <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
      </BrowserRouter>
    </>
  );
}

export default App;
