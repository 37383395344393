import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Khotline from './khotline/Khotline';

const Header = () => {
  const [showDialog, setShowDialog] = useState(false);
    
    const handleOpenDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

  return (
   <div>
  {/* Header START */}
  <header className="header-sticky header-absolute" style={{borderBottom: "1px solid", paddingTop: "0px !important", marginTop: "10px"}}>
    {/* Logo Nav START */}
    <nav className="navbar navbar-expand-md">
      <div className="container">
        {/* Logo START */}
        <Link className="navbar-brand" to={"/"}>
          <img className="light-mode-item navbar-brand-item" src="assets/images/logo-karz.png" alt="logo" />
          {/* <b>Karz</b> */}
        </Link>
        {/* Logo END */}
        
        {/* Buttons */}
        <ul className="nav align-items-center dropdown-hover ms-sm-2">
          {/* Sign up button */}
          <li className="nav-item me-2">
            <Link to={"#"} className="btn btn-lg btn-dark mb-0" style={{fontSize: "25px", "--bs-btn-padding-y": "8px"}} onClick={handleOpenDialog}><b>K-Hotline</b></Link>
          </li>
          {/* Responsive navbar toggler */}
          <li className="nav-item">
            <button className="navbar-toggler ms-sm-3 p-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-animation">
                <span />
                <span />
                <span />
              </span>
            </button>
          </li>	
        </ul>
      </div>
    </nav>
    {/* Logo Nav END */}
    {showDialog && <Khotline onClose={handleCloseDialog} />}

  </header>
  {/* Header END */}
</div>

  )
}

export default Header