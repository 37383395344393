import React, { useState } from "react";
import { toast } from "react-toastify";
import httpRequest from "../utils/Http";
import { POST_LOAN_INQUERY } from "../utils/ApiUrl";
import './Multiform.css'
import { isValidMobile } from "../utils/HelperFunction";

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    step1: { loan_type: "" },
    step2: { name: "", phone: "",  comment: "" },
  });
  const [errors, setErrors] = useState({
    step1: { loan_type: "" },
    step2: { name: "", phone: "", comment: "" },
  });

  const handleNextClick = (loanType) => {
    setFormData({
      ...formData,
      step1: { loan_type: loanType },
    });
    setCurrentStep(2);
  };

  const handleInputChange = (step, fieldName, value) => {
    setFormData({
      ...formData,
      [step]: {
        ...formData[step],
        [fieldName]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    const step2Errors = {};

    const { name, phone, comment } = formData.step2;

    // Validation for required fields
    if (!name) {
      step2Errors.name = "Name is required";
      isValid = false;
    }
    if (!phone) {
      step2Errors.phone = "Phone number is required";
      isValid = false;
    } else if(!isValidMobile(phone)){
      step2Errors.phone = "Enter valid Phone Number";
      isValid = false;
    }

    if (isValid) {
      const res = await httpRequest(POST_LOAN_INQUERY, formData, "POST");
      if (res.status === true) {
        toast.success(res.message);
        resetForm();
      } else {
        toast.error(res.message);
      }
    } else {
      setErrors({ ...errors, step2: step2Errors });
    }
  };

  const resetForm = () => {
    setFormData({
      step1: { loan_type: "" },
      step2: { name: "", phone: "",  comment: "" },
    });
    setCurrentStep(1);
  };

  return (
    <div className="card card-body shadow border p-sm-5">
      <form className="contact-form row g-4" onSubmit={handleSubmit}>
        {currentStep === 1 && (
          <div>
            <div className="ms-3">
              <h6 className="mb-1">Welcome to Karz!</h6>
              <p className="mb-0">What can we help you with?</p>
            </div>
            <br />
            <div className="css-1kb3csv">
  <div role="radiogroup" className="css-1tlib9g">
    <button aria-checked="false" role="radio" tabIndex={0} className="css-3js0wx"  onClick={() => handleNextClick('Loan to buy a new property')}>
      <div className="css-1etrdwv">
        <svg fill="transparent" height={30} viewBox="0 0 36 36" width={30} xmlns="http://www.w3.org/2000/svg">
          <title id="start-purchase-icon">Start Purchase</title>
          <path d="M35.579 13.748 18.703.247a1.124 1.124 0 0 0-1.406 0L.42 13.747a1.125 1.125 0 0 0 .698 2.003h2.25v19.126A1.126 1.126 0 0 0 4.494 36h27.001a1.125 1.125 0 0 0 1.125-1.125V15.75h2.25a1.125 1.125 0 0 0 .709-2.002Zm-4.084-.248a1.125 1.125 0 0 0-1.125 1.125V33.75H5.619V14.625A1.126 1.126 0 0 0 4.494 13.5h-.169l13.67-10.936L31.662 13.5h-.168Z" fill="#017848" />
          <path d="M16.8 13v2.374h-1.2a3.607 3.607 0 0 0-3.118 1.78 3.527 3.527 0 0 0 0 3.56 3.607 3.607 0 0 0 3.118 1.78h4.8c.429 0 .825.227 1.04.594.214.367.214.82 0 1.187-.215.367-.611.593-1.04.593h-4.8c-.318 0-.624-.125-.849-.347a1.18 1.18 0 0 1-.351-.84H12c0 .945.38 1.85 1.054 2.518a3.622 3.622 0 0 0 2.546 1.043h1.2v2.373h2.4v-2.373h1.2a3.607 3.607 0 0 0 3.118-1.78 3.526 3.526 0 0 0 0-3.56A3.607 3.607 0 0 0 20.4 20.12h-4.8c-.429 0-.825-.226-1.04-.593a1.176 1.176 0 0 1 0-1.187c.215-.367.611-.594 1.04-.594h4.8c.318 0 .624.125.849.348.225.222.351.524.351.84H24c0-.945-.38-1.85-1.054-2.519a3.621 3.621 0 0 0-2.546-1.042h-1.2V13h-2.4Z" fill="#017848" />
        </svg>
      </div>
      <span className="css-8y1shf">Loan to buy a new property</span>
    </button>
    <button aria-checked="false" role="radio" tabIndex={-1} className="css-3js0wx" onClick={() => handleNextClick('Refinance current property loan')}>
      <div className="css-1etrdwv">
        <svg fill="transparent" height={30} viewBox="0 0 36 36" width={30} xmlns="http://www.w3.org/2000/svg">
          <title id="start-refinance-icon">Start Refinance</title>
          <path d="M35.579 13.748 18.703.247a1.124 1.124 0 0 0-1.406 0L.42 13.747a1.125 1.125 0 0 0 .698 2.003h2.25v19.126A1.126 1.126 0 0 0 4.494 36h27.001a1.125 1.125 0 0 0 1.125-1.125V15.75h2.25a1.125 1.125 0 0 0 .709-2.002Zm-4.084-.248a1.125 1.125 0 0 0-1.125 1.125V33.75H5.619V14.625A1.126 1.126 0 0 0 4.494 13.5h-.169l13.67-10.936L31.662 13.5h-.168Z" fill="#017848" />
          <path d="M17.6 15v1.768h-.9c-.965 0-1.856.505-2.338 1.326a2.61 2.61 0 0 0 0 2.651 2.709 2.709 0 0 0 2.338 1.326h3.6c.322 0 .619.169.78.442a.87.87 0 0 1 0 .884.903.903 0 0 1-.78.442h-3.6a.908.908 0 0 1-.636-.259.876.876 0 0 1-.264-.625H12c0 .704.284 1.378.79 1.876a2.725 2.725 0 0 0 1.91.776h.9v1.768h1.8v-1.768h.9c.965 0 1.856-.505 2.338-1.326a2.61 2.61 0 0 0 0-2.651 2.709 2.709 0 0 0-2.338-1.326h-3.6a.903.903 0 0 1-.78-.442.87.87 0 0 1 0-.884.903.903 0 0 1 .78-.442h3.6c.239 0 .468.093.636.259.17.165.264.39.264.625H23c0-.704-.285-1.378-.79-1.876a2.725 2.725 0 0 0-1.91-.776h-.9V15h-1.8Z" fill="#017848" />
          <path d="M18.456 10C12.5 10 7.67 14.83 7.67 20.785H6l2.869 4.782 2.865-4.782h-1.667a8.383 8.383 0 0 1 8.389-8.388 8.383 8.383 0 0 1 8.389 8.388 8.383 8.383 0 0 1-8.39 8.389 8.32 8.32 0 0 1-5.919-2.469l-1.702 1.702a10.73 10.73 0 0 0 7.622 3.164c5.956 0 10.785-4.83 10.785-10.786C29.241 14.83 24.411 10 18.456 10Z" fill="#017848" />
        </svg>
      </div>
      <span className="css-8y1shf">Refinance current property loan</span>
    </button>
    <button aria-checked="false" role="radio" tabIndex={-1} className="css-3js0wx" onClick={() => handleNextClick('Loan against property')}>
      <div className="css-1etrdwv">
        <svg fill="transparent" height={30} viewBox="0 0 36 36" width={30} xmlns="http://www.w3.org/2000/svg">
          <title id="start-cashout-refinance-icon">Start Cashout Refinance</title>
          <path d="M2.25 18a15.75 15.75 0 0 1 31.5 0H36a18 18 0 1 0-18 18v-2.25A15.75 15.75 0 0 1 2.25 18Z" fill="#017848" />
          <path d="M23.143 20.204c0-.877-.352-1.718-.98-2.338a3.363 3.363 0 0 0-2.363-.968h-4.457c-.398 0-.766-.21-.965-.551a1.092 1.092 0 0 1 0-1.102c.199-.341.567-.551.965-.551H19.8c.296 0 .579.116.788.323.209.206.326.487.326.779h2.229c0-.877-.352-1.718-.98-2.338a3.363 3.363 0 0 0-2.363-.968h-1.114v-2.204h-2.229v2.204h-1.114a3.35 3.35 0 0 0-2.895 1.653 3.275 3.275 0 0 0 0 3.306 3.35 3.35 0 0 0 2.895 1.653H19.8c.398 0 .766.21.965.551.199.341.199.761 0 1.102-.199.341-.567.551-.965.551h-4.457a1.12 1.12 0 0 1-.788-.323 1.096 1.096 0 0 1-.326-.779H12c0 .877.352 1.718.979 2.338.627.62 1.477.968 2.364.968h1.114v2.204h2.229V23.51H19.8c.887 0 1.737-.348 2.364-.968.627-.62.979-1.461.979-2.338ZM29.852 19.97a1.13 1.13 0 0 0-1.429 0l-5.714 4.653a1.17 1.17 0 0 0-.423.907v9.307c0 .308.12.604.334.822.215.218.506.341.809.341h11.428c.303 0 .594-.123.808-.34.215-.219.335-.515.335-.823V25.53a1.171 1.171 0 0 0-.434-.907l-5.714-4.654Zm3.862 13.703H31.43v-3.49c0-.308-.12-.604-.335-.822a1.133 1.133 0 0 0-.808-.34H28c-.303 0-.594.122-.808.34a1.174 1.174 0 0 0-.335.822v3.49h-2.285V26.09l4.571-3.723 4.571 3.723v7.584Z" fill="#017848" />
        </svg>
      </div>
      <span className="css-8y1shf">Loan against property</span>
    </button>
    <button aria-checked="false" role="radio" tabIndex={-1} className="css-3js0wx" onClick={() => handleNextClick('Loan against property')}>
      <div className="css-1etrdwv">
        <svg fill="transparent" height={30} viewBox="0 0 36 36" width={30} xmlns="http://www.w3.org/2000/svg">
          <title id="start-cashout-refinance-icon">Start Cashout Refinance</title>
          <path d="M2.25 18a15.75 15.75 0 0 1 31.5 0H36a18 18 0 1 0-18 18v-2.25A15.75 15.75 0 0 1 2.25 18Z" fill="#017848" />
          <path d="M23.143 20.204c0-.877-.352-1.718-.98-2.338a3.363 3.363 0 0 0-2.363-.968h-4.457c-.398 0-.766-.21-.965-.551a1.092 1.092 0 0 1 0-1.102c.199-.341.567-.551.965-.551H19.8c.296 0 .579.116.788.323.209.206.326.487.326.779h2.229c0-.877-.352-1.718-.98-2.338a3.363 3.363 0 0 0-2.363-.968h-1.114v-2.204h-2.229v2.204h-1.114a3.35 3.35 0 0 0-2.895 1.653 3.275 3.275 0 0 0 0 3.306 3.35 3.35 0 0 0 2.895 1.653H19.8c.398 0 .766.21.965.551.199.341.199.761 0 1.102-.199.341-.567.551-.965.551h-4.457a1.12 1.12 0 0 1-.788-.323 1.096 1.096 0 0 1-.326-.779H12c0 .877.352 1.718.979 2.338.627.62 1.477.968 2.364.968h1.114v2.204h2.229V23.51H19.8c.887 0 1.737-.348 2.364-.968.627-.62.979-1.461.979-2.338ZM29.852 19.97a1.13 1.13 0 0 0-1.429 0l-5.714 4.653a1.17 1.17 0 0 0-.423.907v9.307c0 .308.12.604.334.822.215.218.506.341.809.341h11.428c.303 0 .594-.123.808-.34.215-.219.335-.515.335-.823V25.53a1.171 1.171 0 0 0-.434-.907l-5.714-4.654Zm3.862 13.703H31.43v-3.49c0-.308-.12-.604-.335-.822a1.133 1.133 0 0 0-.808-.34H28c-.303 0-.594.122-.808.34a1.174 1.174 0 0 0-.335.822v3.49h-2.285V26.09l4.571-3.723 4.571 3.723v7.584Z" fill="#017848" />
        </svg>
      </div>
      <span className="css-8y1shf">Personal, business or other loans</span>
    </button>
  </div>
</div>
          </div>
        )}
        {currentStep === 2 && (
          <div>
                          <div className="row g-4">
                <div className="col-md-12">
                  <label htmlFor="cname" className="form-label heading-color">
                    Name *
                  </label>
                  <input
                    type="text"
                    className="form-control form-control"
                    id="cname"
                    name="name"
                    placeholder="Enter Your Name"
                    value={formData.step2.name}
                    minLength={2}
                    onChange={(e) => handleInputChange("step2", "name", e.target.value)}
                    required
                    aria-required="true"
                  />
                  {errors.step2.name && <span className="text-danger">{errors.step2.name}</span>}
                </div>
                <div className="col-md-12">
                  <label className="form-label heading-color" htmlFor="phone">
                    Phone *
                  </label>
                  <input
                    type="text"
                    className="form-control form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter Your Phone Number"
                    required
                    aria-required="true"
                    value={formData.step2.phone}
                    onChange={(e) => handleInputChange("step2", "phone", e.target.value)}
                  />
                  {errors.step2.phone && <span className="text-danger">{errors.step2.phone}</span>}
                </div>
                <div className="col-md-12">
                  <label className="form-label heading-color" htmlFor="City">
                    Comment *
                  </label>
                  <textarea
                    className="form-control"
                    id="cmessage"
                    name="comment"
                    placeholder="Enter your message here...."
                    style={{ height: "70px" }}
                    value={formData.step1.notes}
                    onChange={(e) => handleInputChange("step2", "comment", e.target.value)}
                  />
                  {errors.step2.comment && <span className="text-danger">{errors.step2.comment}</span>}
                </div>

                <button
                type="submit"
                className="btn btn-lg btn-primary mb-0 form-control"
              >
                Submit
              </button>

              </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default MultiStepForm;
