import React, { useState, useEffect, Suspense } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const LazyLoadedImage = React.lazy(() => import("./LazyLoadedImage"));

const CarouselWithAutoRotate = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, images, interval]);

  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={true}
      showThumbs={false}
      selectedItem={currentIndex}
      onChange={(index) => setCurrentIndex(index)}
    >
      {images.map((image, index) => (
        <Suspense key={index} fallback={<div>Loading...</div>}>
          <LazyLoadedImage src={image} alt={`Slide ${index + 1}`} />
        </Suspense>
      ))}
    </Carousel>
  );
};

export default CarouselWithAutoRotate;
